import {
  ref,
  onMounted,
  reactive,
  watch,
  getCurrentInstance,
  defineComponent
} from 'vue'
import {
  customerRetailUseCase, customerCompanyUseCase, placeGoogleUseCase, voucherDiscountUseCase
} from '@/domain/usecase'
import useVuelidate from '@vuelidate/core'
import {
  required, helpers, maxLength, minLength, numeric
} from '@vuelidate/validators'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { isNull } from 'lodash'
import { checkPermission } from '@/utils/helpers/checkPermission'
import PermissionEnum from '@/utils/enum/permissions'

const moduleStoreAgent = 'appActiveUser/getAgent'

export default defineComponent({
  name: 'CustomerRetailForm',
  setup() {
    const app = getCurrentInstance()
    const {
      $confirm,
      $toast,
      $strInd
    } = app!.appContext.config.globalProperties
    const store = useStore()
    const title = ref('Tambah Pelanggan Retail')
    const router = useRouter()
    const route = useRoute()
    const Id = ref()
    const eventAddress = ref(null) as any
    const address = reactive({
      alamat: null,
      alias: null,
      namaPenerima: null,
      telpPenerima: null
    })
    const dataForm = reactive({
      namaPelanggan: null,
      kodePelanggan: null,
      address: null,
      namaPIC: '',
      website: null,
      kota: null,
      levelDiskonId: null,
      diskon: null,
      isActive: false,
      noKtp: null,
      telepon: null,
      teleponPIC: '',
      email: null,
      fb: null as any,
      ig: null as any,
      tiktok: null as any,
      username: null,
      password: null,
      npwp1: null,
      npwp2: null,
      alamat: null,
      alias: null,
      namaPenerima: null,
      telpPenerima: null,
      id: [],
      userLevelId: null
    })
    const customerId = ref(null)
    const isShowAlamatDialog = ref(false)
    const userAddresses = ref([])
    const submitted = ref(false)
    const levelDiskonOption = ref([])
    const kotaOption = ref([])
    const voucherOption = ref([]) as any
    const voucherCodeId = ref([]) as any
    const voucherCodeIdTemp = ref([]) as any
    const deleteVoucherId = ref([]) as any
    const rawUserVoucher = ref([]) as any
    const op = ref()
    const addressRef = ref()
    const checkRetailOrCompany = () => {
      if (route.name === 'master-pelanggan-retail-edit' || route.name === 'master-pelanggan-retail-tambah') {
        return true
      } return false
    }
    let formLabel
    if (checkRetailOrCompany() === true) {
      formLabel = {
        nama: 'Nama Pelanggan',
        alamat: 'Alamat Lengkap',
        noTelp: 'No. Telepon',
        username: 'Username',
        email: 'Email'
      }
    } else {
      formLabel = {
        nama: 'Nama Perusahaan',
        alamat: 'Alamat Lengkap',
        noTelp: 'No. Telepon Perusahaan'
      }
    }

    const validPhone = (val: any) => {
      // console.log('val', val)
      const regex = new RegExp('^0\\d{1,15}|62\\d{1,15}$')
      // console.log(regex.exec(val))
      return regex.test(val)
    }

    let rules
    if (checkRetailOrCompany() === true) {
      rules = {
        namaPelanggan: {
          required: helpers.withMessage(`${formLabel.nama} harus diisi`, required)
        },
        username: {
          required: helpers.withMessage(`${formLabel.username} harus diisi`, required)
        },
        // kodePelanggan: {
        //   required: helpers.withMessage('Kode Pelanggan harus diisi', required)
        // },
        telepon: {
          required: helpers.withMessage(`${formLabel.noTelp} harus diisi`, required),
          maxLength: helpers.withMessage(`${formLabel.noTelp} tidak boleh melebihi 15 digit`, maxLength(15)),
          firstNumber: helpers.withMessage(`${formLabel.noTelp} harus diawali dengan 0 atau 62`, validPhone)
        },
        noKtp: {
          // required: helpers.withMessage('KTP harus diisi', required)
          required: helpers.withMessage('No KTP harus diisi', required),
          numeric: helpers.withMessage('No KTP harus angka', numeric),
          maxLength: helpers.withMessage('No Ktp Tidak boleh melebihi 16 digit', maxLength(16)),
          minLength: helpers.withMessage('No Ktp Tidak boleh kurang dari 16 digit', minLength(16))
        },
      }
    }
    const v$ = useVuelidate(rules, dataForm)

    const getDataSelectLevelDiskon = async () => {
      const response = await customerRetailUseCase.getAllDataLevelDiskon()
      levelDiskonOption.value = response.result.map((x: any) => ({
        value: x.Id,
        discount: x.Diskon,
        label: `Lvl : ${x.Kelas} - Diskon : ${x.Diskon}% ${x.Attribute1 ? `- ${x.Attribute1}` : ''}`
      }))
      // console.log('ini response', levelDiskonOption.value)
    }
    const getDiscountValueSelected = () => {
      const filltered = levelDiskonOption.value.filter((fill: any) => Number(dataForm.levelDiskonId) === Number(fill.value)) as any
      return filltered.length === 1 ? filltered[0].discount : 0
    }
    const redirectBack = () => {
      router.go(-1)
      // router.push({
      //   name: 'master-pelanggan-retail'
      // })
    }
    const checkResponse = async (response: any) => {
      // console.log('response after', response)
      if (response.error) {
        $toast.add({
          severity: 'error',
          detail: response.message,
          group: 'bc',
          life: 3000
        })
      } else {
        if (Id.value) {
          $toast.add({
            severity: 'success',
            // summary: response.result.title,
            detail: response.result.detail ?? response.result.Detail,
            group: 'bc',
            life: 1500
          })
        } else {
          $toast.add({
            severity: 'success',
            detail: $strInd.toast.successSubmit,
            group: 'bc',
            life: 1500
          })
        }
        if (route.name === 'master-pelanggan-perusahaan-tambah') {
          router.push({
            name: 'master-pelanggan-perusahaan-detail',
            params: {
              idCompany: response.result.Id
            }
          })
        } else {
          redirectBack()
        }
      }
    }
    const submitProccess = async () => {
      console.log('voucherCodeIdTemp', voucherCodeIdTemp)
      console.log('voucherCodeId', voucherCodeId)
      // console.log('dataForm', dataForm)
      // let data
      // if (Id.value) {
      // let data
      let response
      // if (checkRetailOrCompany() === true) {
      const data = {
        // Alamat: dataForm.address,
        // KotaId: dataForm.kota,
        // Password: dataForm.password,
        // Diskon: dataForm.diskon,
        Fullname: dataForm.namaPelanggan,
        PhoneNumber: dataForm.telepon,
        Email: dataForm.email,
        Username: dataForm.username,
        IsActive: dataForm.isActive,
        IdNumber: dataForm.noKtp,
        BirthPlace: null,
        Birthdate: null,
        Gender: null,
        Picture: null,
        Company: null,
        Kurir: null,
        UserLevelId: dataForm.userLevelId,
        ExternalLinks: [
          {
            Type: 'Facebook',
            // Url: dataForm.fb === '-' ? null : dataForm.fb,
            Url: isNull(dataForm.fb) ? '-' : dataForm.fb,
          },
          {
            Type: 'Tiktok',
            // Url: dataForm.tiktok === '-' ? null : dataForm.tiktok,
            Url: isNull(dataForm.tiktok) ? '-' : dataForm.tiktok,
            // Url: dataForm.tiktok,
          },
          {
            Type: 'Instagram',
            // Url: dataForm.ig === '-' ? null : dataForm.ig,
            Url: isNull(dataForm.ig) ? '-' : dataForm.ig,
            // Url: dataForm.ig,
          }
        ]
      }
      response = await customerRetailUseCase.submitData(Id.value, data)
      if (!response.error) {
        if (deleteVoucherId.value.length > 0) {
          // eslint-disable-next-line no-use-before-define
          response = await deleteUserVoucher()
        }
        if (!response.error) {
          const trimmedVoucherId = voucherCodeId.value.filter((x: any) => !voucherCodeIdTemp.value.includes(x))
          if (trimmedVoucherId.length > 0) {
            // eslint-disable-next-line no-use-before-define
            response = await submitUserVoucher(dataForm.username, trimmedVoucherId)
          }
        }
      }
      // }
      checkResponse(response)
    }
    const submitData = (isFormValid: boolean) => {
      // console.log('isFormValid', isFormValid)
      submitted.value = true
      if (!isFormValid) {
        return
      }
      submitProccess()
    }
    const getDataEdit = async () => {
      store.dispatch('showLoading')
      let response
      if (route.name === 'master-pelanggan-retail-edit' || route.name === 'master-pelanggan-retail-tambah') {
        response = await customerRetailUseCase.getDataForm(Id.value)
      } else {
        response = await customerCompanyUseCase.getDataForm(Id.value)
      }
      // const response = await customerRetailUseCase.getDataForm(Id.value)
      // console.log('in response', response)
      title.value = response.title
      if (response.data) {
        const dataGet = response.data.result
        const externalLinks = dataGet.ExternalLinks
        customerId.value = dataGet.Id
        dataForm.namaPelanggan = dataGet.Fullname
        dataForm.isActive = dataGet.IsActive
        dataForm.noKtp = dataGet.IdNumber
        dataForm.telepon = dataGet.PhoneNumber
        dataForm.email = dataGet.Email
        dataForm.username = dataGet.Username
        dataForm.userLevelId = dataGet.UserLevelId

        if (externalLinks.length > 0) {
          externalLinks.forEach((element: any) => {
            if (element.Type === 'Facebook') {
              dataForm.fb = element.Url
            }
            if (element.Type === 'Instagram') {
              dataForm.ig = element.Url
            }
            if (element.Type === 'Tiktok') {
              dataForm.tiktok = element.Url
            }
          })
        }

        dataForm.alamat = dataGet.Retail.PrimaryAddress?.Alamat1
        dataForm.alias = dataGet.Retail.PrimaryAddress?.Alias
        dataForm.namaPenerima = dataGet.Retail.PrimaryAddress?.NamaPenerima
        dataForm.telpPenerima = dataGet.Retail.PrimaryAddress?.HpPenerima
        // eslint-disable-next-line no-use-before-define
        voucherCodeId.value = await getUserVoucher(dataGet.Username)
        voucherCodeIdTemp.value = voucherCodeId.value
        // console.log(voucherCodeId.value)
        // getUserVoucher(dataGet.Username)
      }

      // console.log('dataForm', dataForm)
      // console.log('voucherCodeId', voucherCodeId)
      store.dispatch('hideLoading')
    }
    // eslint-disable-next-line
    const getDecriptId = () => {
      // console.log('decript data', route.params)
      return route.params.idUser
    }
    const deleteData = (val: any) => {
      // console.log(val)
      $confirm.require({
        // header: 'Pelanggan Retail',
        message: $strInd.confirm.msgDelete,
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          store.dispatch('showLoading')
          let response
          if (route.name === 'master-pelanggan-retail-edit' || route.name === 'master-pelanggan-retail-tambah') {
            response = await customerRetailUseCase.deleteData(val)
          } else {
            response = await customerCompanyUseCase.deleteData(val)
          }
          // console.log('reposne delete', response)
          checkResponse(response)
        }
      })
    }

    const getUserAddress = async (id: any) => {
      store.dispatch('showLoading')
      // let dataTemp = null
      const response = await customerRetailUseCase.getUserAddress(id)
      if (response.error) {
        $toast.add({
          severity: 'error', detail: 'Gagal mengambil data, silahkan coba kembali', group: 'bc', life: 3000
        })
      } else {
        userAddresses.value = response.result
        // dataTemp = response.result
        // console.log(userAddresses)
      }
      store.dispatch('hideLoading')
      // return dataTemp
    }

    const showAlamatDialog = async (val: any) => {
      // console.log('custId', val)
      // userAddresses.value = await getUserAddress(val)
      await getUserAddress(val)
      // console.log(userAddresses.value)
      isShowAlamatDialog.value = true
    }

    const closeAlamatDialog = () => {
      isShowAlamatDialog.value = false
    }

    const getKota = async () => {
      const response = await customerRetailUseCase.getSelectKota((checkPermission(PermissionEnum.FilKot) || !checkRetailOrCompany()) ? '' : {
        kotaId: store.getters[moduleStoreAgent]?.kotaId
      })
      kotaOption.value = response.result.map((x: any) => ({
        value: x.Id,
        label: x.Nama
      }))

      if (checkPermission(PermissionEnum.FilKot) && checkRetailOrCompany()) {
        dataForm.kota = store.getters[moduleStoreAgent]?.kotaId
      }
    }

    const getCodes = async (id: any) => {
      const response = await voucherDiscountUseCase.getVoucherHdV2Codes(id)
      if (!response.error) {
        return response.result
      }
    }

    const getVoucherOptions = async () => {
      voucherOption.value = []
      const response = await voucherDiscountUseCase.getVoucherHdV2({
        page: 1,
        top: 1000,
        type: 'Gold'
      })

      if (response.error) {
        $toast.add({
          severity: 'error', detail: 'Gagal mengambil data, silahkan coba kembali', group: 'bc', life: 3000
        })
      } else {
        response.result.Data.map(async (x: any) => {
          const voucherCodes = await getCodes(x.Id)
          const obj = {
            ...x,
            label: x.Name,
            value: voucherCodes[0].Id,
            id: x.Id
          }
          voucherOption.value.push(obj)
        })
        // dataTemp = response.result
        // console.log('voucheroption', voucherOption.value)
      }
    }

    const submitUserVoucher = async (username: any, idVoucher: any) => {
      // console.log([username, idVoucher])
      const response = await voucherDiscountUseCase.submitUserVoucher({
        UserId: username,
        VoucherCodeIds: idVoucher
      })
      return response
    }

    const deleteUserVoucher = async () => {
      // console.log(rawUserVoucher)
      const data = deleteVoucherId.value.map((x: any) => {
        const temp = rawUserVoucher.value.filter((y: any) => y.VoucherCodeId === x)
        // console.log(temp)
        return temp
      })
      // console.log(data)

      const response = await voucherDiscountUseCase.deleteUserVoucher(data[0][0].Id)
      return response
    }

    // eslint-disable-next-line consistent-return
    const getUserVoucher = async (dataUsername: any) => {
      const response = await voucherDiscountUseCase.getUserVoucher({
        username: dataUsername,
        pageNumber: 1,
        pageSize: 1000
      })

      if (response.error) {
        $toast.add({
          severity: 'error', detail: 'Gagal mengambil data voucher user, silahkan coba kembali', group: 'bc', life: 3000
        })
      } else {
        console.log(response)
        const data: string[] = []
        rawUserVoucher.value = response.result.Data
        const UserVoucherLength = rawUserVoucher.value.length
        if (UserVoucherLength > 0) {
          // eslint-disable-next-line array-callback-return
          rawUserVoucher.value.map((x: any) => {
            const temp = x
            // const voucherId = x.Id
            // console.log(voucherId)
            data.push(temp.VoucherCodeId)
            // return voucherCodeId.value.push(voucherId)
          })
        }
        // dataTemp = response.result
        console.log(data)
        return data
      }
    }

    const tagging = (val: any) => {
      // console.log('val', val)
      if (!deleteVoucherId.value.includes(val)) {
        deleteVoucherId.value.push(val)
      }
      console.log('deleteVoucherId', deleteVoucherId.value)
    }

    onMounted(async () => {
      // console.log('addressRef', addressRef.value.$el.getBoundingClientRect())
      Id.value = getDecriptId()
      // console.log('id', Id)
      await getVoucherOptions()
      getDataEdit()
      // getUserAddress()
      // getKota()
      // getDataSelectLevelDiskon()
    })

    const setResultAddress = (result: any) => {
      // console.log('result', result)
      dataForm.address = result
    }

    return {
      levelDiskonOption,
      getDataSelectLevelDiskon,
      title,
      rules,
      v$,
      submitted,
      Id,
      submitData,
      redirectBack,
      route,
      router,
      dataForm,
      app,
      checkResponse,
      getDecriptId,
      getDataEdit,
      store,
      deleteData,
      kotaOption,
      getKota,
      checkRetailOrCompany,
      formLabel,
      address,
      eventAddress,
      setResultAddress,
      op,
      addressRef,
      isShowAlamatDialog,
      showAlamatDialog,
      customerId,
      closeAlamatDialog,
      userAddresses,
      voucherOption,
      voucherCodeId,
      tagging
    }
  }
})
